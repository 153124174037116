<template>
  <div
    id="slot-page"
    class="to-center-abs"
    :style="{
      'background-image': backgroundImage,
    }"
  >
    <modal-lp-bonus v-if="$store.state.auth.lpBonusName" />
    <Spinner
      v-if="slotLoading"
      size="medium"
      context="Loading Game..."
      classes="dead-center"
    ></Spinner>
    <div id="game-container" class="container-fluid pl-0 pr-0">
      <b-row
        v-show="
          slotJackpotsUnlocked &&
          slotJackpotsOnGame &&
          slotJackpotsOnGame.length > 0
        "
        no-gutters
        class="filler-row mx-auto"
      >
        <b-col
          class="jackpot-col col-12 clickable"
          @click="showJackpotInfoModal"
        >
          <div v-show="!slotLoading" class="jackpot-bg to-center-abs">
            <div
              class="amount to-center-abs d-flex align-items-center justify-content-center"
              :class="jackpotTotalAmount.length > 11 ? 'small' : ''"
            >
              {{ jackpotTotalAmount }}
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row align-h="center" class="game-row w-100 mx-auto">
        <b-col
          v-if="!isSidebarExpired"
          v-show="showSidebar"
          class="menu-col h-100 p-0"
        >
          <sidebar-kingspath
            v-if="menu === 'kingspath'"
            :height="menuHeight"
            @expired="disableMenu"
          ></sidebar-kingspath>
          <sidebar-tournament
            v-else-if="menu === 'tournament' && gameData.tournaments"
            :height="menuHeight"
            :data-current-tournament="gameData.tournaments"
            :showanchors="true"
            @expired="disableMenu"
          ></sidebar-tournament>
          <sidebar-champions
            v-else-if="menu === 'champions'"
            :height="menuHeight"
            @expired="disableMenu"
          ></sidebar-champions>
        </b-col>
        <b-col class="game-col h-100">
          <div id="i-frame-container" class="iframe-container">
            <div
              v-if="!slotLoading && !doResize"
              class="close-button"
              @click="$router.go(-1)"
            ></div>
            <div class="fav-button">
              <favorite-badge :game-id="parseInt($route.params.gameId)" />
            </div>
            <div class="i-frame-border">
              <iframe
                id="game-frame"
                :key="url"
                ref="game-frame"
                name="slotContent"
                class="game-iframe"
                :src="url"
                allowfullscreen="allowfullscreen"
                allow="autoplay"
                scrolling="no"
              ></iframe>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <NetentPopup
      @dontShowAgain="handleDontShowAgain"
      @neverShowAgain="handleNeverShowAgain"
    />
    <MicrogamingPopup :bet-multiplier="betMultiplier" :min-bet="customMinBet" />
    <modal-jackpot-list
      v-if="slotJackpotsOnGame"
      :slot-jackpots="slotJackpotsOnGame"
    />
  </div>
</template>

<script>
import * as gameAPI from "@/API/games.js";
import SidebarKingspath from "@/components/slot/sidebar-kingspath";
import SidebarTournament from "@/components/slot/sidebar-tournament";
import NetentPopup from "@/components/slot/netent-popup.vue";
import MicrogamingPopup from "@/components/slot/microgaming-popup.vue";

import Bowser from "bowser";
import Spinner from "@/components/animation/animation-spinner";
import SidebarChampions from "@/components/slot/sidebar-champions";
import ModalJackpotList from "@/components/jackpot/modal-jackpot-list";
import ModalLpBonus from "@/components/others/modal-lp-bonus";
import FavoriteBadge from "@/components/lobby/favorite-badge";

export default {
  name: "SlotPage",
  components: {
    FavoriteBadge,
    ModalLpBonus,
    SidebarChampions,
    ModalJackpotList,
    SidebarTournament,
    SidebarKingspath,
    NetentPopup,
    MicrogamingPopup,
    Spinner,
  },
  props: {
    menu: {
      type: String,
      default: "jackpot",
    },
  },
  data() {
    return {
      gameData: [],
      slotLoading: true,
      error: false,
      url: "",
      settingsOpen: false,
      doResize: false,
      loadingGames: true,
      unsubscribeEvents: [],
      minBet: null,
      intervalId: null,
      showSidebar: false,
      menuHeight: 0,
      betMultiplier: 1,
      customMinBet: 0,
      isSidebarExpired: false,
      slotJackpotsOnGame: null,
    };
  },

  computed: {
    jackpotTotalAmount: function () {
      if (!this.slotJackpotsOnGame) {
        return "0";
      }

      let amount = 0;
      this.slotJackpotsOnGame.forEach((jackpot) => {
        amount += jackpot.amount_current;
      });

      return this.formatNumber(Math.round(amount));
    },

    casinoJackpots() {
      let result = this.$store.state.gameplay.casinoJackpots;
      result = result.sort((a, b) => (a.bet_min > b.bet_min ? -1 : 1)); //sort

      result = this.activateJackpotsByMinBet(result);

      return result;
    },

    musicPreference() {
      return this.$store.getters["sound/getMusicPreference"];
    },

    user: function () {
      return this.$store.getters["user/currentUser"];
    },
    betSizeCurrent: function () {
      return this.$store.getters["gameplay/getCurrentBetSize"];
    },

    publisherSlug() {
      if (!this.gameData) {
        return;
      }
      return this.gameData.game.publisher.slug;
    },
    showNetentPopup() {
      if (this.gameData.game.publisher.slug === "netent") {
        return (
          this.gameData.game.settings.settings_object.layer &&
          this.gameData.game.settings.settings_object.layer.how_to_play &&
          this.$store.getters.showNetentPopup(this.gameData.game.title)
        );
      } else return false;
    },
    showMicrogamingPopup() {
      if (this.gameData.game.publisher.slug === "microgaming") {
        return (
          this.gameData.game.settings.settings.min_bet &&
          this.gameData.game.settings.settings.play_factor
        );
      } else return false;
    },

    // get slot background image
    backgroundImage() {
      if (!this.gameData || !this.gameData.game) {
        return;
      }

      let result;
      let imageArr = this.gameData.game.images;
      result = imageArr.find((img) => img.type === "BACKGROUND");
      if (!result || !result.url) {
        return "none";
      }

      return `url('${result.url}')`;
    },
  },

  watch: {
    //update jackpots activation after every bet size change
    betSizeCurrent: {
      handler(data) {
        if (data) {
          this.minBet = data;
        }
      },
    },

    fallbackMenu: {
      handler() {
        this.setIframeSize();
      },
    },
    displayJackpots() {
      this.setIframeSize();
    },
    musicPreference(data) {
      if (this.publisherSlug === "playngo") {
        let preference;
        if (data) {
          preference = "soundOn";
        } else {
          preference = "soundOff";
        }
        this.postPlayNGoMessage({
          messageType: "request",
          request: preference,
        });
      } else {
        if (data) {
          this.url.replace("sound=0", "sound=1");
        } else {
          this.url.replace("sound=1", "sound=0");
        }
      }
    },
    $route(to, from) {
      if (to.params.gameId !== from.params.gameId) {
        this.fetchGameData();
      }
    },
  },
  created() {
    this.intervalId = setInterval(() => {
      this.$store.dispatch("user/fetchUserLeagueInfos");
    }, 60000);
  },
  mounted() {
    this.fetchGameData()
      .then(() => {
        if (this.showNetentPopup) {
          this.$bvModal.show("netent-popup");
        } else if (this.showMicrogamingPopup) {
          this.betMultiplier = this.gameData.game.settings.settings.play_factor;
          this.customMinBet = this.gameData.game.settings.settings.min_bet;
          this.$bvModal.show("microgaming-popup");
        }
        window.addEventListener("resize", this.setIframeSize);

        const iframe = document.getElementsByTagName("iframe")[0];
        iframe.addEventListener("load", () => {
          this.slotLoading = false;
          this.setIframeSize();
        });
        this.unsubscribeEvents.push(() => {
          iframe.removeEventListener("resize", this.setIframeSize);
        });
      })
      .catch((err) => {
        this.$router.push("/");
        let errorMessage;
        if (err.data && err.data.id === "game.locked") return;
        else if (err && err.data && err.data.message) {
          errorMessage = err.data.message;
        } else {
          errorMessage = this.$t("generic_error_message");
        }
        this.$store.commit("popups/setAlertBannerContent", {
          alertText: errorMessage,
          alertHeading: this.$t("alerts.title.error"),
          variant: "danger",
          type: "danger",
        });
      });
  },
  beforeDestroy() {
    //remove event listeners
    for (let i = 0; i < this.unsubscribeEvents.length; i++) {
      this.unsubscribeEvents[i]();
    }

    //remove iframe. important for old browsers?
    const iframe = this.$refs["game-frame"];
    if (iframe) {
      iframe.parentNode.removeChild(iframe);
    }
    this.$store.commit("gameplay/showEventBox", true);

    clearInterval(this.intervalId);

    //logs
    this.googleTagLogAdvancedEvent({
      event: "level_end",
      level_name: "" + this.gameData.game.id,
    });
  },

  destroyed() {
    //iframe sound still running in background on IE. needs refresh
    const browser = Bowser.getParser(window.navigator.userAgent).getBrowser()
      .name;
    if (browser === "Internet Explorer") {
      location.reload();
    }
  },

  methods: {
    showJackpotInfoModal() {
      this.$bvModal.show("modal-jackpot-list");
    },

    setMinBet: function (minBet) {
      this.minBet = minBet;
    },

    activateJackpotsByMinBet: function (jackpots) {
      for (let i = 0; i < jackpots.length; i++) {
        if (
          jackpots[i].bet_min <= this.minBet &&
          jackpots[i].user_settings.min_level <= this.user.level
        ) {
          jackpots[i].user_settings.status = "active";
        } else if (jackpots[i].user_settings.min_level > this.user.level) {
          jackpots[i].user_settings.status = "locked";
        } else {
          jackpots[i].user_settings.status = "inactive";
        }
      }
      return jackpots;
    },

    disableMenu() {
      this.isSidebarExpired = true;
    },
    fetchGameData() {
      return new Promise((resolve, reject) => {
        gameAPI
          .fetchGameInfo(this.$route.params.gameId)
          .then((result) => {
            //set game iframe data
            this.gameData = result.data.data;
            this.url = result.data.data.session.playurl;
            let paramBegin = "?";
            if (this.url.indexOf("?") !== -1) {
              paramBegin = "&";
            }
            this.url +=
              paramBegin +
              'allowfullscreen="allowfullscreen"&sound=' +
              (this.$store.getters["sound/getMusicPreference"] ? "1" : "0"); //enable or disable sound
            this.loadingGames = false;

            this.minBet = result.data.data.betsize_current;

            this.$store.commit("setIsPageLoading", false);

            //jackpots
            this.slotJackpotsOnGame =
              this.$store.state.gameplay.slotJackpots.filter(
                (g) => g.game_id === this.gameData.game.id
              );

            //logs
            this.googleTagLogAdvancedEvent({
              event: "level_start",
              level_name: "" + this.gameData.game.id,
            });
            this.branchIoLogSlot(this.gameData.game.id);

            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    handleOpenSettings() {
      this.settingsOpen = true;
    },
    handleCloseSettings() {
      this.settingsOpen = false;
    },

    //fit size of iframe to window and containers
    setIframeSize: function () {
      if (this.doResize) {
        return;
      }

      this.doResize = true;

      setTimeout(() => {
        const gameContainer = document.getElementById("game-container");
        const gameRow = document.getElementsByClassName("game-row")[0];
        const fillerRow = document.getElementsByClassName("filler-row")[0];
        const gameCol = document.getElementsByClassName("game-col")[0];
        const iframeContainer = document.getElementById("i-frame-container");
        const menuCol = document.getElementsByClassName("menu-col")[0];
        const menuList = document.getElementsByClassName("sidebar")[0];
        const header = document.getElementById("header-center-container");
        if (!gameContainer) {
          this.doResize = false;
          return;
        }

        this.showSidebar = !!menuList;

        let gameRatio;
        let maxIframeHeight = 888;
        let isSmallScreen =
          window.innerHeight < 700 || window.innerWidth < 1300;
        let isNormalScreen =
          !isSmallScreen &&
          (window.innerHeight < 1080 || window.innerWidth < 1880);
        let isBigScreen = !isSmallScreen && !isNormalScreen;

        // console.log("publisherSlug", this.publisherSlug);
        switch (this.publisherSlug) {
          case "gamomat":
            gameRatio = 1135.5 / 757;
            break;
          case "netent":
            gameRatio = 16 / 9;
            break;
          case "spinomenal":
            gameRatio = 16 / 9;
            break;
          case "microgaming":
            gameRatio = 16 / 9;
            break;
          case "playngo":
            gameRatio = 16 / 9;
            break;
          default:
            gameRatio = 16 / 9;
            break;
        }

        //calc size of menu (sidebar)
        let total_width = gameContainer.offsetWidth;
        let menuWidth;
        if (isSmallScreen) {
          menuWidth = 200;
        }
        if (isNormalScreen) {
          menuWidth = 256;
        }
        if (isBigScreen) {
          menuWidth = 256;
        }
        if (!this.showSidebar) {
          menuWidth = 0;
        }

        //calc height of game iframe
        let heightIframe =
          window.innerHeight -
          header.offsetHeight * 0.82 -
          fillerRow.offsetHeight;

        //set max height
        if (heightIframe > maxIframeHeight) {
          heightIframe = maxIframeHeight;
        }

        // console.log("loggg window.innerHeight", window.innerHeight);
        // console.log("loggg header.offsetHeight", header.offsetHeight);
        // console.log("loggg fillerRow.offsetHeight", fillerRow.offsetHeight);

        //calc width of game iframe
        let newWidthIframe = heightIframe * gameRatio;
        let spaceMenus = 0;
        if (window.innerWidth < 2000) {
          spaceMenus = 250;
        }
        if (newWidthIframe + menuWidth > total_width - spaceMenus) {
          let difference = total_width - (newWidthIframe + menuWidth);
          newWidthIframe += difference - spaceMenus;
          heightIframe = newWidthIframe / gameRatio;
        }

        //set values to elements
        let newTotalWidth = newWidthIframe + menuWidth;
        if (this.showSidebar) {
          menuList.style.height = heightIframe + "px";
          menuList.style.width = menuWidth + "px";
          menuCol.style.maxWidth = menuWidth + "px";
          fillerRow.style.maxWidth = newTotalWidth + "px";
          this.menuHeight = heightIframe;
        }

        iframeContainer.style.height = heightIframe + "px";
        iframeContainer.style.width = newWidthIframe + "px";

        //set new max-width of row container to center content
        gameRow.style.maxWidth = newTotalWidth + "px";
        gameCol.style.maxWidth = newWidthIframe + "px";

        this.doResize = false;
      }, 300);
    },

    postPlayNGoMessage(data) {
      let element = document.getElementById("game-frame");
      let source = element.contentWindow;
      let targetOrigin = process.env.VUE_APP_PLAYNGO_BASE_URL;
      source.postMessage(data, targetOrigin);
    },

    handleDontShowAgain(event) {
      if (event) {
        this.$store.commit("addNoPopupGame", this.gameData.game.title);
      } else {
        this.$store.commit("removeNoPopupGame", this.gameData.game.title);
      }
    },
    handleNeverShowAgain(boolean) {
      this.$store.commit("hideNetentPopups", boolean);
    },
  },
};
</script>

<style lang="scss">
#slot-page {
  .item-filler-container {
    padding-right: 3px;
  }
  .spinner-container {
    top: -10%;
    z-index: 10;
  }
}

.resize-page {
  #app-content {
    height: 100vh;

    main {
      min-height: 0;
    }
  }
}
</style>

<style scoped lang="scss">
#slot-page {
  top: 0;
  height: 100vh;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  #game-container {
    height: 100%;
    padding-top: 8.125rem;
    position: relative;

    @media (min-width: 2800px) and (min-height: 880px) {
      padding-top: 8.75rem;
    }

    .sidebar {
      z-index: 30;
    }
    .filler-row {
      .jackpot-col {
        height: 55px;
        .jackpot-bg {
          top: 1px;
          width: 758px;
          height: 100%;
          background: url(~@/assets/img/slot/slot-jackpot-top-panel.png)
            center/100% 65px no-repeat;
          margin-left: auto;
          margin-right: auto;

          .amount {
            font-size: 24px;
            color: white;
            font-family: Ubuntu-Bold;
            text-shadow: 0 0 0.313rem #000000, 0 0 0.313rem #000000,
              0 0 0.188rem #000000;
            text-align: center;
            left: 22px;
            height: 100%;
            top: 2px;

            &.small {
              font-size: 15px;
            }
          }
        }
      }
    }
    .game-row {
      flex-wrap: nowrap;
    }

    .game-col {
      padding: 0 0 0 5px;
    }

    #i-frame-container {
      position: relative;
      display: inline-block;

      .close-button {
        right: -60px;
        top: -90px;
        width: 120px;
        height: 120px;
        background: url(~@/assets/img/common/btn-close-default.svg) center/100%
          100% no-repeat;
        z-index: 99;

        @media (max-height: 720px) {
          top: -40px;
          right: -30px;
          width: 70px;
          height: 70px;
        }
      }

      .fav-button {
        position: absolute;
        z-index: 99;
        top: -35px;
        left: 0px;

        @media (max-height: 720px) {
          top: -15px;
          left: -11px;
        }
      }

      .i-frame-border {
        z-index: 1;
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
      }

      #game-frame {
        z-index: 10;
        height: 100%;
        width: 100%;
        border: none;
        overflow: hidden;
      }
    }
  }
}
</style>
