<template>
  <div
    v-if="data"
    id="sidebar-tournament"
    class="sidebar"
    name="sidebar"
    :style="{ fontSize: height / 55.5 + 'px' }"
  >
    <div class="menu-container">
      <div class="head-icon to-center-abs" :class="$store.state.locale"></div>

      <div class="timer-container to-center-abs text-center">
        <div class="timer-text">
          <template v-if="tournamentStarted">
            {{ $t("kingspath.quest_end_in") }}:
          </template>
          <template v-else-if="!tournamentEnded">
            {{ $t("tournaments.info.start_time") }}:
          </template>
        </div>
        <div class="timer dark-bg d-flex flex-column justify-content-center">
          <template v-if="tournamentStarted">
            <CountdownDigital
              :time="endDate"
              prefix="- "
              :with-time-diff="true"
              @over="updateStarted"
            />
          </template>
          <template v-else-if="!tournamentEnded">
            <CountdownDigital
              :time="startDate"
              prefix="- "
              :with-time-diff="true"
              @over="updateStarted"
            />
          </template>
          <template v-else>
            <div class="green">{{ $t("tournaments.info.ended") }}</div>
          </template>
        </div>
      </div>

      <div class="mode-container to-center-abs text-center">
        <div class="mode-text">
          {{ $t("tournaments.info.mode") }}
        </div>
        <div class="mode dark-bg d-flex flex-column justify-content-center">
          {{ mode }}
        </div>
      </div>

      <div class="prize-container to-center-abs text-center">
        <div class="prize-text">
          {{ $t("tournaments.v2.prize_pool") }}
        </div>
        <div class="prize dark-bg2 d-flex flex-column justify-content-center">
          <div class="prize-coin-text">
            <span class="icon-gold"></span>
            {{ formatNumber(data.amount) }}
          </div>
        </div>
      </div>

      <div class="separator to-center-abs"></div>

      <!--      max bet overlay-->
      <div
        v-if="isMaxBet && !isPopupDisable && !tournamentEnded"
        class="max-bet-overlay to-center-abs"
      >
        <div class="icon-caution to-center-abs"></div>
        <div class="max-bet-headline to-center-abs">
          {{ $t("tournaments.v2.notification.max_bet.header") }}:
          <div class="big">
            {{ formatNumber(data.limit) }} {{ $t("reward.gold") }}
          </div>
        </div>
        <div class="max-bet-text to-center-abs">
          {{
            $t("tournaments.v2.notification.max_bet.text", {
              0: formatNumber(data.limit),
            })
          }}
        </div>
        <button
          class="max-bet-button ok-button-green to-center-abs"
          @click="handleDontShowMaxBet"
        >
          {{ $t("tournaments.notification.max_bet.button_ok") }}
        </button>
      </div>

      <!--      ended overlay-->
      <div v-else-if="tournamentEnded" class="ended-overlay to-center-abs">
        <div class="icon-ended to-center-abs"></div>
        <div class="ended-headline to-center-abs">
          {{ $t("tournaments.notification.finished.title") }}
        </div>
        <div class="ended-text to-center-abs">
          {{ $t("tournaments.v2.notification.finished.message") }}
        </div>
        <button
          class="ended-button ok-button-green to-center-abs"
          @click="$emit('expired')"
        >
          {{ $t("tournaments.notification.finished.button_ok") }}
        </button>
      </div>

      <!--      user ranking list-->
      <div v-else class="user-list-content to-center-abs">
        <div
          v-for="(item, index) in scoreboard"
          :key="item.user_id"
          class="user-item-container"
        >
          <ItemSidebarTournament
            :item="item"
            :history-item="historyItem"
            :show-more-infos="showMoreInfos"
            :type="data.type"
            :mode="mode"
            :index="index"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CountdownDigital from "../base/countdown-digital";
import ItemSidebarTournament from "./item-sidebar-tournament.vue";
import * as tournamentAPI from "@/API/tournament.js";

export default {
  name: "SidebarTournament",
  components: {
    CountdownDigital,
    ItemSidebarTournament,
  },
  props: {
    dataCurrentTournament: { type: Object, required: false, default: null },
    dataHistory: { type: Object, required: false, default: null },
    historyItem: { type: Boolean, default: false },
    height: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      tournamentStarted: null,
      tournamentEnded: false,
      data: null,
      testing: false,
      intervalId: null,
      showMoreInfos: false,
    };
  },

  computed: {
    updatedTournament: function () {
      return this.$store.getters["tournaments/getTournamentCurrentUpdateData"];
    },
    scoreboard: function () {
      if (this.data === null || !this.data) {
        return;
      }

      if (!this.data.ranking.scoreboard) {
        return this.data.ranking.scoreboard;
      }

      let maxScore = 5;
      if (this.historyItem) {
        maxScore = 4;
      }
      let result = this.data.ranking.scoreboard.slice(0, maxScore);

      //sort by rank
      result = result.sort((a, b) => a.rank - b.rank);

      //user is in top players. return list
      for (let i = 0; i < maxScore; i++) {
        if (!result[i] || result[i].user_id === this.user.id) {
          return result;
        }
      }

      if (!this.data.ranking.personal_best) {
        return result;
      }
      //user is not in top players. add him to the end of the list
      if (this.data.ranking.personal_best.rank > maxScore - 1) {
        result = this.data.ranking.scoreboard.slice(0, maxScore - 1);
        result.push(this.data.ranking.personal_best);
      }

      return result;
    },

    tournament: function () {
      if (this.data === null) {
        return;
      }

      return true;
    },

    startDate: function () {
      if (!this.data) {
        return;
      }

      let then = new Date(this.data.start_date);
      return then.getTime();
    },
    endDate: function () {
      if (!this.data) {
        return;
      }

      let then = new Date(this.data.end_date);
      return then.getTime();
    },

    mode: function () {
      if (!this.data && !Array.isArray(this.data)) {
        return;
      }

      let type = this.data.type;
      let limit = "";
      if (this.data.limit) {
        limit = this.formatNumber(this.data.limit);
      }
      return this.$t("tournaments.type." + type.toLowerCase(), { 0: limit });
    },

    gameName: function () {
      if (!this.data || !this.dataGames) {
        return;
      }

      const game_id = this.data.game_id;
      for (let i = 0; i < this.dataGames.length; i++) {
        if (this.dataGames[i].id === game_id) {
          return this.dataGames[i].title;
        }
      }

      return "";
    },

    user: function () {
      return this.$store.getters["user/currentUser"];
    },
    duration: function () {
      if (!this.data) {
        return;
      }

      let begin = new Date(this.data.start_date).getTime();
      let then = new Date(this.data.end_date).getTime();

      let distance = then - begin;

      // Time calculations
      let hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

      return hours * 60 + minutes + ":00";
    },

    dataGames: function () {
      return this.$store.getters["gameplay/getGames"];
    },
    isMaxBet: function () {
      if (!this.data) {
        return;
      }
      //not slot view
      if (!this.$route.params.gameId) {
        return;
      }

      return this.data.type === "TOTAL_WIN_LIMIT";
    },

    isPopupDisable: function () {
      return this.$store.getters.getHideMaxBetPopups;
    },
  },

  watch: {
    updatedTournament: {
      handler(data) {
        if (data) {
          if (
            parseInt(data.tournament.game_id) !==
            parseInt(this.$route.params.gameId)
          ) {
            return;
          }

          console.log("update tournament...", data);
          let newData = { ...this.data };
          newData.ranking = data.ranking;
          this.data = newData;
          this.$store.commit(
            "tournaments/setTournamentCurrentUpdateData",
            null
          );
        }
      },
    },
  },

  beforeDestroy() {
    clearInterval(this.intervalId);
  },

  mounted: function () {
    if (this.historyItem) {
      this.data = this.dataHistory;
    } else {
      this.fetchTournament();
    }

    this.intervalId = setInterval(() => {
      this.showMoreInfos = !this.showMoreInfos;
    }, 5000);
  },

  methods: {
    fetchTournament: async function () {
      // TEST
      if (this.testing) {
        this.data = JSON.parse(
          '{"id":29554,"game_id":145,"type":"INSTANT_WIN","win_type":"a","limit":null,"amount":27937080,"start_date":"2021-01-13T09:42:14.000000Z","end_date":"2022-05-13T10:42:14.000000Z","image_path":"https:\\/\\/storage.kleinekrone.de\\/uploads\\/games\\/814cfaf8ed_romanlegion.jpg","ranking":{"scoreboard":[{"user_id":5,"name":"Oliver","avatar_url":"https:\\/\\/storage.kleinekrone.de\\/avatar\\/knight.png","amount":78000000,"reward":1395420,"rank":1},{"user_id":599,"name":"jennycb87","avatar_url":"https:\\/\\/storage.kleinekrone.de\\/avatar\\/princess.png","amount":20000000,"reward":1325649,"rank":2},{"user_id":3088,"name":"Gast964","avatar_url":"https:\\/\\/storage.kleinekrone.de\\/avatar\\/prince.png","amount":2260000,"reward":1259366,"rank":3},{"user_id":2,"name":"Edelritter","avatar_url":"https:\\/\\/storage.kleinekrone.de\\/avatar\\/knight.png","amount":1000000,"reward":1196405,"rank":4},{"user_id":3520,"name":"Uwe Geller","avatar_url":"https:\\/\\/storage.kleinekrone.de\\/avatar\\/thief.png","amount":5000,"reward":1136583,"rank":5}],"personal_best":{"user_id":2,"name":"Edelritter","avatar_url":"https:\\/\\/storage.kleinekrone.de\\/avatar\\/knight.png","amount":1000000,"reward":1196405,"rank":5}}}'
        );
        this.updateStarted();
        return;
      }

      if (this.dataCurrentTournament.active.length > 0) {
        await tournamentAPI
          .getActiveTournamentByGameId(this.$route.params.gameId)
          .then((result) => {
            this.data = Object.values(result)[0];
            this.updateStarted();
          });
      } else if (this.dataCurrentTournament.upcoming.length > 0) {
        this.dataCurrentTournament.upcoming[0].ranking = [];
        this.data = this.dataCurrentTournament.upcoming[0];
        this.updateStarted();
      } else {
        this.$emit("expired");
      }
    },

    updateStarted: function () {
      if (!this.data) {
        return;
      }
      let now = this.getCurrentDate();
      let start = new Date(this.data.start_date);
      let end = new Date(this.data.end_date);
      if (now.getTime() <= start.getTime()) {
        this.tournamentStarted = false;
      } else if (
        now.getTime() >= start.getTime() &&
        now.getTime() <= end.getTime()
      ) {
        this.tournamentStarted = true;
      } else if (now.getTime() >= end.getTime()) {
        this.tournamentEnded = true;
        this.tournamentStarted = null;
      }
    },
    handleDontShowMaxBet() {
      this.$store.commit("setHideMaxBetPopups", true);
    },
  },
};
</script>

<style scoped lang="scss">
#sidebar-tournament {
  height: 100%;
  width: 100%;
  position: relative;
  background-image: linear-gradient(#274b60, #0a1d28);
  border-radius: 5%;

  .menu-container {
    height: 100%;
    background: url(~@/assets/img/slot/sidebar/slotsidebar-frame-main.png)
      center/100% 100% no-repeat;

    .dark-bg {
      background-color: #050d1c;
      box-shadow: 0 0 0.2em #038fa7;
      border-bottom: 0.125em solid #038fa7;
      border-radius: 0.5em;
      width: 86%;
      margin-left: auto;
      margin-right: auto;
    }

    .dark-bg2 {
      width: 86%;
      margin-left: auto;
      margin-right: auto;
      background: url(~@/assets/img/slot/sidebar/slotsidebar-bg-prizepool.png)
        center/100% 100% no-repeat;
    }

    .head-icon {
      height: 8.938em;
      top: -3%;

      &.de {
        background: url(~@/assets/img/slot/sidebar/tourney-head-logo-de.png)
          center/contain no-repeat;
      }

      &.en {
        background: url(~@/assets/img/slot/sidebar/tourney-head-logo-en.png)
          center/contain no-repeat;
      }
    }

    .timer-container {
      top: 14.5%;

      .timer-text {
        font-size: 1em;
        color: #ff0000;
        font-family: Ubuntu-bold;
        text-transform: uppercase;
      }

      .timer {
        font-size: 1.625em;
        padding: 0.2em 0 0.2em 0;
        color: #ff0000;
        font-family: Ubuntu-bold;

        .green {
          font-size: 26px;
          font-family: Ubuntu-bold;
          color: #43ff00;
          text-transform: uppercase;
        }
      }
    }

    .mode-container {
      top: 23.7%;

      .mode-text {
        font-size: 1em;
        color: #4de5ff;
        font-family: Ubuntu-bold;
        text-transform: uppercase;
      }

      .mode {
        font-size: 1em;
        color: #4de5ff;
        font-family: Ubuntu-bold;
        padding: 0.2em;
        height: 5em;

        .smaller-text {
          font-size: 0.8em;
        }
      }
    }

    .prize-container {
      top: 36.5%;

      .prize-text {
        font-size: 1em;
        color: #fdf28d;
        font-family: Ubuntu-bold;
        text-transform: uppercase;
      }

      .prize {
        padding: 0.2em;
        height: 2.938em;

        .prize-coin-text {
          font-size: 1.625em;
          color: #fdf28d;
          font-family: Ubuntu-bold;
          padding-left: 23%;
          text-align: left;

          .icon-gold {
            width: 1.5em;
            height: 1.5em;
            background: url(~@/assets/img/icons/icon-final-gold.png) center/100%
              100% no-repeat;
            position: absolute;
            top: 44%;
            left: 10%;
          }

          .smaller-text {
            font-size: 0.8em;
          }
        }
      }
    }

    .separator {
      top: 47%;
      width: 104%;
      left: -2%;
      height: 0.73em;
      background: url(~@/assets/img/slot/sidebar/slot-sidebar-divider.png)
        center/100% 100% no-repeat;
    }

    .max-bet-overlay {
      top: 47.8%;
      height: 51.8%;
      width: 97%;

      .icon-caution {
        top: 8%;
        width: 3em;
        height: 2.688em;
        background: url(~@/assets/img/slot/sidebar/icon-attention-blue.png)
          center/100% 100% no-repeat;
      }

      .max-bet-headline {
        top: 23%;
        font-size: 1.125em;
        font-family: Ubuntu-Bold;
        color: #4de5ff;
        text-align: center;
        text-transform: uppercase;
        line-height: 1.625em;

        .big {
          font-size: 1.625em;
          font-family: Ubuntu-Bold;
          color: #4de5ff;
        }
      }

      .max-bet-text {
        top: 40%;
        width: 90%;
        font-size: 1.375em;
        font-family: Ubuntu-Medium;
        color: white;
        text-align: center;
        line-height: 1.225em;
      }

      .max-bet-button {
        top: 78%;
        width: 80%;
        height: 2.75em;
        border-radius: 1.375em;
        font-size: 1.313em;
        font-family: Ubuntu-Bold;
        text-shadow: 0.063rem 0.063rem 0.125rem #0b2b00, 0 0 1.563rem #42ff00,
          0 0 0.313rem #0b2b00;
        padding-top: 0.25em;
      }
    }

    .ended-overlay {
      top: 47.8%;
      height: 51.8%;
      width: 97%;

      .icon-ended {
        top: 8%;
        width: 8em;
        height: 3.688em;
        background: url(~@/assets/img/slot/sidebar/tourney-end-checkmark-w-coins.png)
          center/100% 100% no-repeat;
      }

      .ended-headline {
        top: 25%;
        width: 90%;
        font-size: 1.75em;
        font-family: Cinzel-Bold;
        color: #fff8b7;
        text-align: center;
        text-transform: uppercase;
        line-height: 1.2em;
      }

      .ended-text {
        top: 42%;
        width: 90%;
        font-size: 1.375em;
        font-family: Ubuntu-Medium;
        color: white;
        text-align: center;
        line-height: 1.225em;
      }

      .ended-button {
        top: 78%;
        width: 80%;
        height: 2.75em;
        border-radius: 1.375em;
        font-size: 1.313em;
        font-family: Ubuntu-Bold;
        text-shadow: 0.063rem 0.063rem 0.125rem #0b2b00, 0 0 1.563rem #42ff00,
          0 0 0.313rem #0b2b00;
        padding-top: 0.25em;
      }
    }

    .user-list-content {
      top: 47.8%;
      height: 51.8%;
      width: 97%;
      overflow: hidden;
      background-image: linear-gradient(to top, #274b60, #0a1d28);
      border-bottom-left-radius: 0.75em;
      border-bottom-right-radius: 0.75em;

      .user-item-container {
        height: 20%;
      }
    }
  }
}
</style>
