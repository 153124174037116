<template>
  <div
    id="sidebar-kingspath"
    class="sidebar"
    name="sidebar"
    :style="{ fontSize: height / 30 + 'px' }"
  >
    <div v-if="mission" class="menu-container">
      <div class="head-icon to-center-abs" :class="$store.state.locale"></div>

      <div class="timer-container to-center-abs text-center">
        <div class="timer-text">{{ $t("kingspath.quest_end_in") }}:</div>
        <div class="timer dark-bg">
          <CountdownDigital
            :time="endDate"
            :with-time-diff="true"
            @over="$emit('expired')"
          />
        </div>
      </div>

      <div class="station-container to-center-abs text-center">
        <div class="station-text">
          {{ $t("kingspath.map.quest.level") }}
        </div>
        <div class="station dark-bg">
          {{ mission.mission.level }}
        </div>
      </div>

      <div class="separator to-center-abs"></div>

      <div class="task-img to-center-abs">
        <div class="text to-center-abs">
          {{ $t("kingspath.quest.unlock.title") }}
        </div>
      </div>

      <div class="menu-card to-center-abs">
        <KingspathCard
          :mission="mission"
          :opened="true"
          :index="0"
          :config="config"
        ></KingspathCard>
      </div>

      <div class="progress-container to-center-abs text-center">
        <div class="progress-text">
          {{ $t("kingspath.quest.progress") }}:
          <span class="progress-bar-text">{{ Math.round(progress) }}%</span>
        </div>
        <div class="progress-bar">
          <div
            class="progress-bar-fill"
            :style="{ width: progress + '%' }"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import KingspathCard from "../kingspath/kingspath-card";
import CountdownDigital from "../base/countdown-digital";
import * as kingspathAPI from "@/API/kingspath.js";
import Vue from "vue";

export default {
  name: "SidebarKingspath",
  components: {
    CountdownDigital,
    KingspathCard,
  },
  props: {
    height: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      data: null,
    };
  },

  computed: {
    mission: function () {
      if (this.data === null || this.data.current === undefined) {
        return;
      }

      const missions = this.data.current.missions;
      for (let i = 0; i < missions.length; i++) {
        if (missions[i].status === "ACTIVE") {
          return missions[i];
        }
      }

      return missions[0];
    },

    endDate: function () {
      if (!this.mission) {
        return;
      }

      return new Date(this.mission.timeout_at).getTime();
    },

    progress: function () {
      if (this.mission === null) {
        return;
      }

      return this.mission.progress.percentage;
    },
    updateKingspathMissionUpdate: function () {
      return this.$store.getters["kingspath/getKingspathMissionUpdate"];
    },
    config: function () {
      return this.$store.getters["getGeneralConfig"].settings;
    },
  },

  watch: {
    updateKingspathMissionUpdate: {
      handler(data) {
        if (data) {
          this.$store.commit("kingspath/setKingspathMissionUpdate", null);

          //get index of current Mission
          let index = 0;
          for (let i = 0; i < this.data.current.missions.length; i++) {
            if (this.data.current.missions[i].status === "ACTIVE") {
              index = i;
              break;
            }
          }

          //update mission progress
          let temp_progress = this.data.current.missions[index];
          temp_progress.progress = data.progress;
          Vue.set(this.data.current.missions, index, temp_progress);
        }
      },
    },
  },

  mounted: function () {
    console.log("menuheight", this.menuHeight);

    this.fetchKingspath();
  },

  methods: {
    fetchKingspath: async function () {
      await kingspathAPI.fetchKingspathOverview().then((res) => {
        if (res.current.missions.length <= 0) {
          this.$emit("expired");
        }
        this.data = res;
      });
    },
  },
};
</script>

<style lang="scss">
#sidebar-kingspath {
  .kingspath-card-open {
    width: 100%;
    height: 100%;

    img {
      height: 100%;
    }

    .kingspath-card-open-game-img {
      top: 12%;
    }

    .kingspath-card-open-text1 {
      font-size: 0.66em;
      top: 56%;
    }

    .kingspath-card-open-difficulty-text {
      font-size: 0.78em;
      width: 80%;
      height: 1.5em;
    }
  }

  .menu-card {
    .progress-bar {
      display: none;
    }
  }
}
</style>

<style scoped lang="scss">
#sidebar-kingspath {
  height: 100%;
  width: 100%;
  position: relative;
  background-image: linear-gradient(#274b60, #0a1d28);
  border-radius: 5%;

  .menu-container {
    height: 100%;
    background: url(~@/assets/img/slot/sidebar/slotsidebar-frame-main.png)
      center/100% 100% no-repeat;

    .dark-bg {
      background-color: #050d1c;
      box-shadow: 0 0 0.2em #038fa7;
      border-bottom: 2px solid #038fa7;
      border-radius: 0.5em;
      width: 86%;
      margin-left: auto;
      margin-right: auto;
    }

    .head-icon {
      height: 25%;
      top: -3.5%;

      &.de {
        background: url(~@/assets/img/slot/sidebar/sidebar-kingspath-logo-de.png)
          center/contain no-repeat;
      }

      &.en {
        background: url(~@/assets/img/slot/sidebar/sidebar-kingspath-logo-en.png)
          center/contain no-repeat;
      }
    }

    .timer-container {
      top: 21%;

      .timer-text {
        font-size: 0.55em;
        color: #ff0000;
        font-family: Ubuntu-bold;
        text-transform: uppercase;
      }

      .timer {
        font-size: 0.8em;
        color: #ff0000;
        font-family: Ubuntu-bold;
      }
    }

    .station-container {
      top: 30%;

      .station-text {
        font-size: 0.55em;
        color: #4de5ff;
        font-family: Ubuntu-bold;
        text-transform: uppercase;
      }

      .station {
        font-size: 0.8em;
        color: #4de5ff;
        font-family: Ubuntu-bold;
      }
    }

    .separator {
      top: 39%;
      width: 104%;
      left: -2%;
      height: 0.73em;
      background: url(~@/assets/img/slot/sidebar/slot-sidebar-divider.png)
        center/100% 100% no-repeat;
    }

    .task-img {
      top: 39%;
      height: 24%;
      background: url(~@/assets/img/slot/sidebar/sidebar-kingspath-title-quest.png)
        center/contain no-repeat;

      .text {
        color: white;
        text-transform: uppercase;
        font-size: 0.77em;
        top: 47%;
        text-align: center;
      }
    }

    .menu-card {
      top: 59%;
      width: 6.8em;
      height: 9em;
    }

    .progress-container {
      top: 91%;

      .progress-text {
        color: white;
        font-family: Ubuntu-Medium;
        font-size: 0.8em;
        text-transform: uppercase;

        .progress-bar-text {
          color: #4de5ff;
          font-family: Ubuntu-Bold;
          font-size: 0.8em;
        }
      }

      .progress-bar {
        height: 100%;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        overflow: visible;
        margin-top: 0.1em;

        .progress-bar-fill {
          background-color: #4de5ff;
          height: 0.23em;
          box-shadow: 0 0 10px rgba(77, 229, 255, 0.58),
            0 0 20px rgba(77, 229, 255, 0.58), 0 0 30px rgba(77, 229, 255, 0.58);
        }
      }
    }
  }
}
</style>
