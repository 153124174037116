<template>
  <b-modal
    id="modal-jackpot-list"
    class="custom-modal1"
    :hide-footer="true"
    modal-class="custom-modal1"
  >
    <template slot="modal-header" slot-scope="{ close }">
      <a href="#" class="close-button" @click="close()"> </a>
    </template>

    <template slot="default" slot-scope="{ close }">
      <div
        class="headline to-center-abs d-flex align-items-center justify-content-center"
      >
        <div class="text">
          {{ $t("jackpot_info.headline") }}
        </div>
      </div>

      <!--        CASINO JACKPOTS-->
      <div class="list-box box1 to-center-abs">
        <div
          class="list-box-headline to-center-abs d-flex align-items-center justify-content-center"
        >
          <span class="jackpot-name">
            {{ $t("jackpot_info.box1.headline1") }}
          </span>
          <span class="jackpot-valid">
            {{ $t("jackpot_info.box1.headline2") }}
          </span>
        </div>

        <div class="list-box-main">
          <b-table
            id="raffle-overview"
            small
            :items="casinoJackpots"
            :fields="fields"
            thead-class="head-row-text"
            sort-by="min_bet"
          >
            <template v-slot:table-colgroup>
              <col key="name" style="width: 30%" />
              <col key="amount" style="width: 40%" />
              <col key="min_bet" style="width: 30%" />
            </template>
            <template v-slot:cell(name)="data">
              {{ data.item.name }}
            </template>

            <template v-slot:cell(amount)="data">
              {{ formatNumber(data.item.amount_current) }}
              <span class="icon-gold"></span>
            </template>

            <template v-slot:cell(bet_min)="data">
              {{ formatNumber(data.item.bet_min) }}
              <span class="icon-gold"></span>
            </template>
          </b-table>
        </div>
      </div>

      <div class="list-box box2 to-center-abs">
        <div
          class="list-box-headline to-center-abs d-flex align-items-center justify-content-center"
        >
          <span class="jackpot-name">
            {{ $t("jackpot_info.box2.headline1") }}
          </span>
          <span class="jackpot-valid">
            {{ $t("jackpot_info.box2.headline2") }}
          </span>
        </div>

        <div class="list-box-main">
          <b-table
            id="raffle-overview"
            small
            :items="slotJackpotsClean"
            :fields="fields"
            thead-class="head-row-text"
            sort-by="min_bet"
          >
            <template v-slot:table-colgroup>
              <col key="name" style="width: 30%" />
              <col key="amount" style="width: 40%" />
              <col key="min_bet" style="width: 30%" />
            </template>
            <template v-slot:cell(name)="data">
              Jackpot {{ data.index + 1 }}
            </template>

            <template v-slot:cell(amount)="data">
              {{ formatNumber(data.item.amount_current) }}
              <span class="icon-gold"></span>
            </template>

            <template v-slot:cell(bet_min)="data">
              {{ formatNumber(data.item.bet_min) }}
              <span class="icon-gold"></span>
            </template>
          </b-table>
        </div>
      </div>

      <div
        class="ok-button-green round to-center-abs clickable d-flex align-items-center justify-content-center"
        @click="close()"
      >
        {{ $t("jackpot_info.button") }}
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "ModalJackpotList",
  mixins: [],
  props: {
    slotJackpots: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "name",
          label: this.$t("jackpot_info.table.column1"),
        },
        {
          key: "amount",
          label: this.$t("jackpot_info.table.column2"),
        },
        {
          key: "bet_min",
          label: this.$t("jackpot_info.table.column3"),
        },
      ],
    };
  },

  computed: {
    casinoJackpots() {
      return this.$store.getters["gameplay/getCasinoJackpots"];
    },

    slotJackpotsClean() {
      if (!this.slotJackpots) {
        return;
      }
      const result = this.slotJackpots;
      return result.slice(0, 5);
    },
  },
  mounted: function () {
    // this.$bvModal.show("modal-jackpot-list");
  },
};
</script>
<style lang="scss">
$modal-width: 1000px;
$modal-height: 1000px;

#modal-jackpot-list {
  .modal-dialog {
    top: 0;
    max-width: $modal-width;
    height: $modal-height;

    @media (max-height: 900px) {
      transform: scale(0.8);
      top: -100px;
      margin-bottom: -20%;
    }

    @media (max-height: 740px) {
      transform: scale(0.7);
      top: -150px;
      margin-bottom: -20%;
    }
  }

  .modal-content {
    width: $modal-width;
    background: transparent;
  }

  .close-button {
    top: -10px;
    right: 50px;
    width: 120px;
    height: 120px;
    background-image: url(~@/assets/img/shop/offers/btn-close-default.png);
  }

  .list-box-main {
    th {
      font-size: 12px;
    }
    td {
      position: relative;
    }
  }
}
</style>

<style scoped lang="scss">
#modal-jackpot-list {
  .headline {
    top: 0;
    width: 616px;
    height: 54px;
    border-radius: 27px;
    background-color: #0d2535;
    border: 2px solid #ffffff;
    box-shadow: 0 0 26px rgba(77, 229, 255, 0.6),
      0 0 26px rgba(77, 229, 255, 0.6), 0 0 26px rgba(77, 229, 255, 0.6),
      0 0 26px rgba(77, 229, 255, 0.6), 0 0 13px rgba(77, 229, 255, 0.6),
      0 0 13px rgba(77, 229, 255, 0.6), 0 0 6px rgba(0, 94, 110, 0.8),
      0 0 6px rgba(0, 94, 110, 0.8);

    .text {
      text-transform: uppercase;
      font-size: 36px;
      font-family: Ubuntu-Bold;
      text-align: center;
    }
  }

  .list-box {
    width: 616px;
    height: 296px;
    border-radius: 16px;
    background-color: #0d2535;
    border: 2px solid #ffffff;
    box-shadow: 0 0 26px rgba(77, 229, 255, 0.6),
      0 0 26px rgba(77, 229, 255, 0.6), 0 0 26px rgba(77, 229, 255, 0.6),
      0 0 26px rgba(77, 229, 255, 0.6), 0 0 13px rgba(77, 229, 255, 0.6),
      0 0 13px rgba(77, 229, 255, 0.6), 0 0 6px rgba(0, 94, 110, 0.8),
      0 0 6px rgba(0, 94, 110, 0.8);

    &.box1 {
      top: 100px;
    }

    &.box2 {
      top: 445px;
    }

    .list-box-headline {
      top: -23px;
      width: 454px;
      height: 41px;
      border-radius: 27px;
      background: linear-gradient(#0e1c24 80%, #173242);
      border: 1px solid #0e1c24;
      box-shadow: 0 0 17px rgba(77, 229, 255, 0.6),
        0 0 4px rgba(77, 229, 255, 0.6), 0 0 4px rgba(77, 229, 255, 0.6);

      .jackpot-name {
        font-family: Ubuntu-Bold;
        font-size: 21px;
        text-shadow: 0 0 13px rgba(77, 229, 255, 0.6),
          0 0 6px rgba(77, 229, 255, 0.6), 0 0 6px rgba(77, 229, 255, 0.6),
          0 0 3px rgba(0, 94, 110, 0.8), 0 0 3px rgba(0, 94, 110, 0.8);
        text-transform: uppercase;
      }

      .jackpot-valid {
        margin-left: 20px;
        font-family: Ubuntu-Bold;
        font-size: 18px;
        text-shadow: 0 0 13px rgba(77, 229, 255, 0.4),
          0 0 6px rgba(77, 229, 255, 0.6), 0 0 6px rgba(77, 229, 255, 0.4),
          0 0 3px rgba(0, 94, 110, 0.6), 0 0 3px rgba(0, 94, 110, 0.6);
      }
    }

    .list-box-main {
      margin-top: 25px;

      .icon-gold {
        width: 16px;
        height: 16px;
        background: url(~@/assets/img/common/coin1.png) center/100% 100%
          no-repeat;
        display: block;
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        right: 25px;
      }
    }
  }

  .ok-button-green {
    top: 780px;
    width: 316px;
    height: 48px;
    text-transform: uppercase;
    font-size: 21px;
    padding-top: 3px;
  }
}
</style>
