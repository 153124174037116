<template>
  <b-modal
    id="modal-lp-bonus"
    class="custom-modal2"
    :hide-footer="true"
    modal-class="custom-modal2"
    content-class="rectangular-dark-blue-modal"
    @hidden="onClose"
  >
    <template slot="modal-header" slot-scope="{ close }">
      <a href="#" class="close-button" @click="close()"> </a>
      <div class="head-icon to-center-abs"></div>
    </template>

    <template slot="default">
      <div id="buy-complete-container">
        <div class="complete-icon to-center-abs"></div>
        <div class="headline to-center-abs">
          {{ $t("alerts.lp_rewards_received.title") }}
        </div>

        <div v-if="gold > 0" class="package-amount glow">
          {{ formatNumber(gold) }} x

          <div class="main-currency-icon gold">
            <img src="@/assets/img/common/coin1.png" class="w-100" />
          </div>
        </div>

        <div v-if="crown > 0" class="package-amount glow">
          {{ formatNumber(crown) }} x

          <div class="main-currency-icon crown">
            <img src="@/assets/img/common/crown4.png" class="w-100" />
          </div>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "ModalLpBonus",
  components: {},
  data() {
    return {
      packageName: null,
    };
  },

  computed: {
    gold() {
      let lpGold =
        this.$store.state.generalConfig.settings["user.register.campaigns"]
          .exit_intend.default.gold;

      // // newsletter gold
      // let settings = this.$store.state.generalConfig.settings;
      // if (settings["user.newsletter.register.gold"]) {
      //   lpGold += settings["user.newsletter.register.gold"];
      // }

      return lpGold;
    },
    crown() {
      return this.$store.state.generalConfig.settings["user.register.campaigns"]
        .exit_intend.default.crown;
    },
  },

  mounted: function () {
    this.$bvModal.show("modal-lp-bonus");
  },

  methods: {
    onClose() {
      this.$store.dispatch("user/updateUser");
      this.$store.commit("auth/setLpBonusName", null);
    },
  },
};
</script>
<style lang="scss">
$modal-width: 530px;
$modal-height: 380px;

#modal-lp-bonus {
  .modal-header {
    .head-icon {
      top: -135px;
      width: 265px;
      height: 229px;
      background: url(~@/assets/img/profile/graphic-newsletter-coins@2x.png)
        center/100% no-repeat;
      z-index: 10;
    }
  }
  .modal-dialog {
    top: 100px;
    max-width: $modal-width;
    min-height: $modal-height;
    height: auto;

    @media (max-height: 785px) {
      transform: scale(0.9);
      top: 120px;
      margin-bottom: -10%;
    }

    @media (max-height: 700px), (max-width: 1200px) {
      transform: scale(0.75);
      top: 50px;
      margin-bottom: -25%;
    }
  }

  .modal-content {
    width: $modal-width;
    box-shadow: 0 0 26px rgba(77, 229, 255, 0.6),
      0 0 26px rgba(77, 229, 255, 0.6), 0 0 26px rgba(77, 229, 255, 0.6),
      0 0 26px rgba(77, 229, 255, 0.6), 0 0 13px rgba(77, 229, 255, 0.6),
      0 0 13px rgba(77, 229, 255, 0.6), 0 0 6px rgba(0, 94, 110, 0.8),
      0 0 6px rgba(0, 94, 110, 0.8);
    border: 2px solid white;
    min-height: $modal-height;
    border-radius: 16px;
  }
}
</style>

<style scoped lang="scss">
#modal-lp-bonus {
  .glow {
    text-shadow: 0 0 16px rgba(77, 229, 255, 0.6),
      0 0 8px rgba(77, 229, 255, 0.6), 0 0 8px rgba(77, 229, 255, 0.6),
      0 0 4px rgba(0, 94, 110, 0.8), 0 0 4px rgba(0, 94, 110, 0.8);
  }

  .glowing-border {
    box-shadow: 0 0 26px rgba(77, 229, 255, 0.6),
      0 0 26px rgba(77, 229, 255, 0.6), 0 0 26px rgba(77, 229, 255, 0.6),
      0 0 26px rgba(77, 229, 255, 0.6), 0 0 13px rgba(77, 229, 255, 0.6),
      0 0 13px rgba(77, 229, 255, 0.6), 0 0 6px rgba(0, 94, 110, 0.8),
      0 0 6px rgba(0, 94, 110, 0.8) !important;
  }

  #buy-complete-container {
    margin-top: -30px;
  }

  .package-amount {
    font-family: Ubuntu-Bold;
    font-size: 48px;
    position: relative;
    display: inline-flex;
    text-align: center;
    top: 250px;
    line-height: 0.7;
  }

  .main-currency-icon {
    width: 48px;
    margin-left: 15px;
    margin-top: -5px;
  }

  .complete-icon {
    top: 50px;
    background: url(~@/assets/img/shop/icn-shop-buy-success.png) center/100%
      100% no-repeat;
    width: 70px;
    height: 70px;
  }

  .headline {
    top: 120px;
    font-family: Ubuntu-Bold;
    font-size: 36px;
    text-transform: uppercase;
    color: #43ff00;
  }
}
</style>
