<template>
  <div
    v-if="item.user_id"
    class="item-sidebar-tournament"
    @click="fetchPublicUser(item.user_id)"
  >
    <div
      class="battle-item-bg"
      :class="[
        {
          'own-rank': isOwnRank,
          even: index % 2 === 0,
        },
      ]"
    >
      <div class="row no-gutters align-items-center h-100">
        <!--        left side-->
        <div class="col-3">
          <!--          rank number-->
          <div v-if="item.rank > 1" class="rank-number">
            <div>{{ formatNumber(item.rank) }}</div>
          </div>
          <div v-else class="icon-number-one"></div>
        </div>

        <div class="col-9">
          <!--          username-->
          <div class="user-name text-left">
            {{ shortenString(item.name, 11) }}
          </div>

          <!--          sub text between-->
          <div class="sub-text text-left">{{ shortModeString }}:</div>

          <!--          status of win amount-->
          <div class="value-status text-left">
            <div
              class="gold-amount"
              :class="[{ 'smaller-text': item.amount.toString().length > 9 }]"
            >
              {{ formatNumber(item.amount) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import getPublicUserInfos from "@/mixins/getPublicUserInfos.js";

export default {
  name: "ItemSidebarTournament",
  mixins: [getPublicUserInfos],
  props: {
    item: {
      type: Object,
      required: true,
    },
    historyItem: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: "",
    },
    mode: {
      type: String,
      required: false,
      default: "",
    },
    index: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    shortModeString() {
      const arr = this.mode.split(" ");
      if (arr.length < 2) {
        return this.mode;
      }

      return arr[0] + " " + arr[1];
    },
    isOwnRank: function () {
      return this.item.user_id === this.currentUser.id;
    },
    currentUser: function () {
      return this.$store.getters["user/currentUser"];
    },
  },
};
</script>

<style scoped lang="scss">
.item-sidebar-tournament {
  position: relative;
  height: 100%;
  cursor: pointer;

  .battle-item-bg {
    height: 100%;

    &.even {
      background: transparent;
    }
    &:not(.even) {
      background: rgba(238, 238, 238, 0.1);
    }

    &.own-rank {
      background-color: rgba(67, 255, 0, 0.12);
      .rank-number,
      .user-name,
      .sub-text,
      .gold-amount {
        color: #43ff00;
      }
    }

    .rank-number {
      color: white;
      font-size: 1.125em;
      font-family: Ubuntu-bold;
      text-align: center;
      height: 2.5em;
    }

    .icon-number-one {
      background: url(~@/assets/img/slot/sidebar/1-badge-table-default-2-x.png)
        center/100% 100% no-repeat;
      width: 2.938em;
      height: 3.625em;
      margin: -0.188em auto 0 auto;
    }

    .user-name {
      color: white;
      font-size: 1.125em;
      font-family: Ubuntu-bold;
      text-align: center;
      text-transform: uppercase;
    }

    .sub-text {
      color: #4de5ff;
      font-size: 0.875em;
      font-family: Ubuntu-medium;
      text-align: center;
      line-height: 1.55em;
    }

    .value-status {
      line-height: 1.55em;
      .gold-amount {
        color: #4de5ff;
        font-family: Ubuntu-bold;
        font-size: 1.313em;
        background: url(~@/assets/img/slot/sidebar/gold-sidebar-entry.png)
          left/1em 1em no-repeat;
        padding-left: 1.25em;
        &.smaller-text {
          font-size: 1.1em;
        }
      }

      .idle {
        background: url(~@/assets/img/slot/sidebar/gold-sidebar-entry.png)
          left/1em 1em no-repeat;
        padding-left: 1.25em;
        font-family: Ubuntu-bold;
        color: #8e8e8e;
        font-size: 1.313em;
      }
    }
  }
}
</style>
