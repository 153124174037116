<template>
  <b-modal
    id="microgaming-popup"
    :modal-class="`custom-modal-transparent ${$store.state.locale}`"
    :hide-footer="true"
    :hide-header="true"
  >
    <template slot="default">
      <div class="modal-contents dead-center">
        <b-row id="microgaming-info-tables" no-gutters class="mx-auto"
          ><b-col id="microgaming-info" class="pr-3" cols="8">
            <div
              id="multiplier-field"
              class="gradient-indent d-inline-flex align-items-center justify-content-around"
            >
              <span class="blue bold"
                >x {{ formatNumber(customMultiplier) }}</span
              >
              <img draggable="false" src="@/assets/img/common/coin1.png" />
            </div>
            <p class="text-uppercase blue bold mb-0 mt-3">
              {{ $t("slot.microgaming_info.bet_multiplier") }}
            </p>
            <p class="text-white">
              {{
                $t("slot.microgaming_info.paragraph", {
                  0: formatNumber(customMultiplier),
                  1: formatNumber(customMultiplier * 2),
                  2: formatNumber(customMultiplier * 100),
                })
              }}
            </p>
            <p class="bold blue mt-5">
              {{
                $t("slot.microgaming_info.min_bet", {
                  0: formatNumber(customMinBet),
                })
              }}
            </p></b-col
          ><b-col id="microgaming-example" cols="4"
            ><b-row
              align-h="center"
              class="text-uppercase blue bold"
              style="font-size: 21.4px"
              >____ {{ $t("slot.microgaming_info.example") }}____</b-row
            ><b-row class="example-row"
              ><b-col cols="5" class="text-left" style="font-size: 28.6px">{{
                $t("slot.microgaming_info.bet")
              }}</b-col
              ><b-col
                cols="7"
                class="text-right bold p-0"
                style="font-size: 26px"
                >2</b-col
              ></b-row
            ><b-row class="example-row"
              ><b-col cols="5" class="text-left" style="font-size: 20px">{{
                $t("slot.microgaming_info.multiplier")
              }}</b-col
              ><b-col
                cols="7"
                class="text-right bold p-0"
                style="font-size: 26px"
                >x {{ formatNumber(customMultiplier) }}</b-col
              ></b-row
            ><b-row class="example-row add-border pb-3"
              ><b-col cols="5" class="text-left" style="font-size: 25px">{{
                $t("slot.microgaming_info.total_bet")
              }}</b-col
              ><b-col
                cols="7"
                class="text-right bold p-0"
                style="font-size: 26px"
                >= {{ formatNumber(customMultiplier * 2) }}</b-col
              ></b-row
            ><b-row class="example-row mt-3"
              ><b-col cols="5" class="text-left" style="font-size: 25px">{{
                $t("slot.microgaming_info.win")
              }}</b-col
              ><b-col
                cols="7"
                class="text-right bold p-0"
                style="font-size: 26px"
                >100</b-col
              ></b-row
            ><b-row class="example-row"
              ><b-col cols="5" class="text-left" style="font-size: 20px">{{
                $t("slot.microgaming_info.gold_won")
              }}</b-col
              ><b-col
                cols="7"
                class="text-right bold p-0"
                style="font-size: 26px"
                >= {{ formatNumber(customMultiplier * 100) }}</b-col
              ></b-row
            ></b-col
          ></b-row
        >
      </div>
      <div id="chest-img" class="bg-img position-absolute"></div>
      <button
        id="go-button"
        class="to-center-abs bg-img button-empty clickable text-white text-uppercase bold"
        style="font-size: 42px"
        @click="$bvModal.hide('microgaming-popup')"
      >
        {{ $t("events.button_lets_go") }}
      </button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "MicrogamingPopup",
  props: {
    betMultiplier: {
      type: Number,
      default: 1,
    },
    minBet: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      dontShowAgainCheck: false,
      neverShowAgainCheck: false,
    };
  },
  computed: {
    customMultiplier() {
      return this.betMultiplier * 100;
    },
    customMinBet() {
      return this.customMultiplier * this.minBet;
    },
  },
  methods: {},
};
</script>
<style lang="scss">
#microgaming-popup {
  &.en {
    .modal-dialog {
      background: url(~@/assets/img/modal/mega-multiplier-bg_en.png) center/100%
        100% no-repeat;
    }
  }
  .modal-dialog {
    height: 820px;
    width: 1278px;
    max-width: 1278px;
    background: url(~@/assets/img/modal/mega-multiplier-bg_de.png) center/100%
      100% no-repeat;
    @media (max-width: 1278px), (max-height: 850px) {
      height: 642px;
      width: 1000px;
      max-width: 1000px;
    }
    @media (max-height: 700px) {
      top: -70px;
    }
  }

  .modal-header {
    border: none !important;
    justify-content: center;
    text-transform: uppercase;
    font-size: 22px;
    color: $light-gold;
    .close-button {
      right: -16px;
      top: -16px;
      width: 52px;
      height: 52px;
    }
  }
  .modal-contents {
    width: 813px;
    height: 366px;
    top: 180px;
    @media (max-width: 1278px), (max-height: 850px) {
      transform: scale(0.8);
    }

    #microgaming-info {
      text-align: left;
      #multiplier-field {
        width: 374px;
        height: 58px;
        border-radius: 28.8px;
        box-shadow: 0 0 20px rgba(77, 229, 255, 0.59),
          0 0 5px rgba(77, 229, 255, 0.59), 0 0 5px rgba(77, 229, 255, 0.41);
        span {
          font-size: 46px;
          text-shadow: 0 0 10px rgba(77, 229, 255, 0.53),
            0 0 2px rgba(0, 0, 0, 0.5), 0 0 2px rgba(0, 0, 0, 0.8);
        }
        img {
          height: 49px;
          width: 49px;
        }
      }
      span,
      p {
        font-size: 21px;
      }
    }

    #microgaming-example {
      color: $light-blue;
      font-size: 21.4px;
      .example-row {
        align-items: center;
        flex-wrap: nowrap;
        &.add-border {
          border-bottom: 2.4px solid #3a5162;
        }
      }
    }
    .blue-checkmark {
      top: 5px;
    }
  }
  #chest-img {
    width: 332px;
    height: 303px;
    background-image: url(~@/assets/img/modal/multiplier-chest.png);
    bottom: -46px;
    right: 107px;
    @media (max-width: 1278px), (max-height: 850px) {
      transform: scale(0.8);
      bottom: -74px;
      right: 50px;
    }
  }
  #go-button {
    bottom: -46px;
    background-image: url(~@/assets/img/buttons/button-blue-fancy.png);
    width: 442px;
    height: 197px;
    padding-left: 30px;
    @media (max-width: 1278px), (max-height: 850px) {
      transform: scale(0.8);
    }
  }
}
</style>
