<template>
  <b-modal id="netent-popup" modal-class="custom-modal2" :hide-footer="true">
    <template slot="modal-header">
      <DecoCorners color-variant="blue" />
      <h1 class="super-headline3-light" style="font-size: 44px">
        {{ $t("slot.netent_info.title") }}
      </h1>
      <div
        role="button"
        href="#"
        class="close-button"
        @click="$bvModal.hide('netent-popup')"
      ></div>
    </template>
    <template slot="default">
      <div class="modal-contents">
        <b-row id="netent-info-display" no-gutters align-v="start"> </b-row>
        <b-row id="netent-info-tables" no-gutters class="mx-auto"
          ><b-col id="netent-footnotes" cols="8"
            ><b-row class="footnote-row"
              ><b-col class="footnote-number-col" cols="1"
                ><div class="footnote-number bold">1</div></b-col
              ><b-col class="footnote-text-col"
                ><p class="blue bold text-uppercase mb-0">
                  {{ $t("slot.netent_info.info_1.title") }}
                </p>
                <p class="text-white">
                  {{ $t("slot.netent_info.info_1.message") }}
                </p></b-col
              ></b-row
            ><b-row class="footnote-row"
              ><b-col class="footnote-number-col" cols="1"
                ><div class="footnote-number bold">2</div></b-col
              ><b-col class="footnote-text-col"
                ><p class="blue bold text-uppercase mb-0">
                  {{ $t("slot.netent_info.info_2.title") }}
                </p>
                <p class="text-white">
                  {{ $t("slot.netent_info.info_2.message") }}
                </p></b-col
              ></b-row
            ><b-row class="footnote-row"
              ><b-col class="footnote-number-col" cols="1"
                ><div class="footnote-number bold">3</div></b-col
              ><b-col class="footnote-text-col"
                ><p class="blue bold text-uppercase mb-0">
                  {{ $t("slot.netent_info.info_3.title") }}
                </p>
                <p class="text-white">
                  {{ $t("slot.netent_info.info_3.message") }}
                </p></b-col
              ></b-row
            ></b-col
          ><b-col id="netent-example"
            ><b-row align-h="center" class="text-uppercase blue bold"
              >____ {{ $t("slot.netent_info.example") }}____</b-row
            ><b-row class="example-row"
              ><b-col class="text-left">Bet</b-col
              ><b-col class="text-right bold">20</b-col></b-row
            ><b-row class="example-row add-border mb-3"
              ><b-col class="text-left">Value</b-col
              ><b-col cols="2" class="text-right">x</b-col
              ><b-col class="text-right bold">4.000</b-col></b-row
            ><b-row class="example-row mt-3"
              ><b-col class="text-left">{{ $t("slot.netent_info.bet") }}</b-col
              ><b-col cols="2" class="text-right">=</b-col
              ><b-col class="text-right bold">80.000</b-col></b-row
            ></b-col
          ></b-row
        ><b-row no-gutters align-h="end" align-v="center"
          ><div class="form-group mb-0 mr-3">
            <label
              tabindex="0"
              role="checkbox"
              class="modern-check-label"
              for="dont-show-again-check"
              @change="$emit('dontShowAgain', dontShowAgainCheck)"
              ><div class="pseudo-label">
                {{ $t("slot.netent_info.do_not_show_again") }}
              </div>
              <input
                id="dont-show-again-check"
                v-model="dontShowAgainCheck"
                type="checkbox"
                tabindex="-1" /><span class="blue-checkmark"></span
            ></label>
          </div>
          <div class="form-group mb-0">
            <label
              tabindex="0"
              role="checkbox"
              class="modern-check-label"
              for="never-show-again-check"
              @change="$emit('neverShowAgain', neverShowAgainCheck)"
              ><div class="pseudo-label">
                {{ $t("slot.netent_info.never_show_again") }}
              </div>
              <input
                id="never-show-again-check"
                v-model="neverShowAgainCheck"
                type="checkbox"
                tabindex="-1" /><span class="blue-checkmark"></span
            ></label></div
        ></b-row>
      </div>
    </template>
  </b-modal>
</template>

<script>
import DecoCorners from "@/components/base/deco-corners.vue";
export default {
  name: "NetentPopup",
  components: { DecoCorners },
  data() {
    return {
      dontShowAgainCheck: false,
      neverShowAgainCheck: false,
    };
  },
  computed: {},
  methods: {},
};
</script>
<style lang="scss">
#netent-popup {
  .modal-dialog {
    width: 992px;
    height: 645px;
    max-width: 992px;

    @media (max-height: 700px) {
      transform: scale(0.8);
      top: -50px;
      margin-bottom: -20%;
    }
  }

  .modal-header {
    border: none !important;
    justify-content: center;
    text-transform: uppercase;
    font-size: 22px;
    color: $light-gold;
    .close-button {
      right: -16px;
      top: -16px;
      width: 52px;
      height: 52px;
    }
  }
  .modal-contents {
    #netent-info-display {
      height: 200px;
      background: url(~@/assets/img/slot/demo-ui-panel.png) center/100% 100%
        no-repeat;
    }
    #netent-info-tables {
      width: 90%;
    }
    #netent-footnotes {
      .footnote-number {
        width: 36px;
        height: 36px;
        border-radius: 100%;
        border: 1.5px solid #ffe600;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffe600;
        font-size: 24px;
      }
      .footnote-text-col {
        text-align: left;
        font-size: 20px;
      }
    }
    #netent-example {
      color: $light-blue;
      font-size: 21.4px;
      .example-row {
        font-size: 28.6px;
        &.add-border {
          border-bottom: 2.4px solid #3a5162;
        }
      }
    }
    .blue-checkmark {
      top: 5px;
    }
  }
}
</style>
