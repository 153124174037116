<template>
  <div
    v-if="currentBattle && settings"
    id="sidebar-champions"
    class="sidebar"
    name="sidebar"
    :style="{ fontSize: height / 55.5 + 'px' }"
  >
    <div class="menu-container">
      <div class="head-icon to-center-abs"></div>

      <!--      match number-->
      <div class="number-container to-center-abs text-center">
        <div class="number-text">
          {{ $t("sidebar.champions.match_number") }}:
        </div>
        <div class="number dark-bg d-flex flex-column justify-content-center">
          {{ completeBattleAmount + 1 }}
        </div>
      </div>

      <!--      timer-->
      <div v-if="endDate" class="timer-container to-center-abs text-center">
        <div class="timer-text">{{ $t("kingspath.quest_end_in") }}:</div>
        <div class="timer dark-bg d-flex flex-column justify-content-center">
          <CountdownDigital
            :time="endDate.getTime()"
            prefix="- "
            :with-time-diff="true"
            @over="doEndBattle"
          />
        </div>
      </div>

      <!--      separator-->
      <div class="separator to-center-abs"></div>

      <!--      player container-->
      <div v-if="!matchEnded" class="player-container to-center-abs">
        <!--        player 1 (own user)-->
        <div class="player player1 to-center-abs">
          <div class="player-avatar-container to-center-abs">
            <div class="wreath left to-center-abs"></div>
            <div class="wreath right to-center-abs"></div>
            <div class="player-avatar-container green to-center-abs">
              <div
                class="player-avatar"
                :style="{ backgroundImage: 'url(' + player1.avatar_url + ')' }"
              ></div>
            </div>
            <div class="player-name to-center-abs">
              {{ shortenString(player1.name) }}
            </div>
          </div>
          <div class="subline to-center-abs">
            {{ $t("sidebar.champions.subtext") }}
          </div>
          <div
            class="gold-amount to-center-abs d-flex align-items-center justify-content-center"
          >
            {{ formatNumber(currentBattle.gold) }}
            <span class="gold-icon"></span>
          </div>
        </div>

        <div class="vs-image to-center-abs"></div>

        <!--        player 2-->
        <div
          class="player player2 to-center-abs clickable"
          :class="'user-id-' + opponentId"
          @click="openProfile"
        >
          <div class="player-avatar-container to-center-abs">
            <div class="wreath left to-center-abs"></div>
            <div class="wreath right to-center-abs"></div>
            <div class="player-avatar-container to-center-abs">
              <div
                v-if="player2"
                class="player-avatar"
                :style="{
                  backgroundImage: 'url(' + player2.user.avatarUrl + ')',
                }"
              ></div>
              <div v-else class="placeholder-avatar">?</div>
            </div>
            <div v-if="player2" class="player-name to-center-abs">
              {{ shortenString(player2.user.name) }}
            </div>
          </div>
          <template v-if="player2">
            <div class="subline to-center-abs">
              {{ $t("sidebar.champions.subtext") }}
            </div>
            <div class="lock to-center-abs"></div>
          </template>

          <template v-else>
            <div class="waiting-text to-center-abs">
              {{ $t("sidebar.champions.looking_for_player") }}
            </div>
          </template>
        </div>
      </div>

      <!--      ended overlay-->
      <div v-else-if="!isReturnMode" class="ended-overlay to-center-abs">
        <div class="ended-headline to-center-abs">
          {{ $t("sidebar.champions.win.text1") }}
        </div>
        <div class="ended-text1 to-center-abs">
          {{ $t("sidebar.champions.win.text2") }}
        </div>

        <div class="ended-text2 to-center-abs">
          {{ $t("sidebar.champions.win.text3") }}
        </div>
        <button
          class="ended-button ok-button-green to-center-abs"
          @click="
            () => {
              isReturnMode = true;
            }
          "
        >
          {{ $t("ok") }}
        </button>
      </div>

      <!--      return overlay-->
      <div v-else class="return-overlay to-center-abs">
        <div class="return-headline to-center-abs">
          {{ $t("sidebar.champions.win.text4") }}
        </div>
        <div class="return-text1 to-center-abs">
          {{ $t("sidebar.champions.win.text5") }}
        </div>

        <button
          class="return-button1 ok-button-green to-center-abs"
          @click="nextBattle"
        >
          {{ $t("sidebar.champions.next.battle") }}
        </button>
        <button
          class="return-button2 ok-button-green to-center-abs"
          @click="returnToChampions"
        >
          {{ $t("sidebar.champions.next.back") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CountdownDigital from "@/components/base/countdown-digital";
import * as ChampionsAPI from "@/API/champions.js";
import dateFunctions from "@/mixins/dateFunctions.js";
import * as profileAPI from "@/API/profile";
import { getSettings } from "@/API/static-config";
import getPublicUserInfos from "@/mixins/getPublicUserInfos.js";
export default {
  name: "SidebarChampions",
  components: {
    CountdownDigital,
  },
  mixins: [dateFunctions, getPublicUserInfos],
  props: {
    height: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      testing: false,
      battleDuration: 300,
      battleExpired: false,
      player2: null,
      settingsData: null,
      isReturnMode: false,
    };
  },

  computed: {
    settings() {
      if (this.settingsData) {
        return this.settingsData.data;
      }
      return null;
      // return this.$store.state.generalConfig.settings;
    },

    userId() {
      return this.$store.getters["user/currentUser"].id;
    },

    opponentId() {
      return this.$store.getters["champions/getCurrentOpponentId"];
    },

    completeBattleAmount() {
      return this.$store.getters["champions/getCompleteBattleAmount"];
    },

    player1() {
      return this.$store.getters["user/currentUser"];
    },

    currentBattle() {
      return this.$store.getters["champions/getCurrentBattle"];
    },

    matchNumber() {
      return this.currentBattle.battle_id;
    },

    endDate() {
      // return new Date("2022-09-27T04:54:55.000000Z").getTime();

      if (!this.currentBattle.started_at) {
        return;
      }

      if (!this.settings) {
        return;
      }

      let interval = this.battleDuration;
      if (this.settings["champions.user.battle.interval"]) {
        interval = this.settings["champions.user.battle.interval"];
      }
      let date = new Date(this.currentBattle.started_at);
      date.setSeconds(date.getSeconds() + interval + 1);

      return date;
    },

    matchEnded() {
      if (this.battleExpired) {
        return true;
      }

      if (!this.currentBattle.started_at) {
        return false;
      }

      const today = this.getCurrentDate();
      return this.isDateInPast(this.endDate, today);
    },
  },
  watch: {
    opponentId: {
      handler(value) {
        if (this.player2) {
          return;
        }
        this.fetchPlayer2(value);
      },
    },
  },

  beforeDestroy() {
    if (this.currentBattle) {
      let now = this.getCurrentDate();

      console.log("now", now);
      console.log("endDate", this.endDate);
      if (now > this.endDate) {
        console.log("EXPIRED!!!!");
        this.doExpireSidebar();
      }
    }
  },
  created() {
    this.fetchSettings();
  },
  mounted() {
    if (!this.currentBattle) {
      this.doExpireSidebar();
      return;
    }

    this.isReturnMode = false;
    this.battleExpired = false;

    this.fetchBattle();
  },

  methods: {
    fetchBattle() {
      ChampionsAPI.getBattleById(this.matchNumber)
        .then((res) => {
          //find and update player 2
          let oponentId = null;
          res.battle.user_battles.forEach((bat) => {
            if (bat.user_id !== this.userId) {
              oponentId = bat.user_id;
            }
          });

          if (oponentId) {
            this.fetchPlayer2(oponentId);
          }
        })
        .catch((e) => console.log(e));
    },

    doEndBattle() {
      this.battleExpired = true;
    },

    doExpireSidebar() {
      this.$store.commit("champions/setCurrentBattle", null);
      this.$store.commit("champions/setCurrentOpponentId", null);
    },

    fetchPlayer2(id) {
      if (!id) {
        return;
      }
      profileAPI
        .fetchUserById(id)
        .then((data) => {
          this.player2 = data.data.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    openProfile() {
      this.fetchPublicUser(this.player2.user.id);
    },
    fetchSettings() {
      getSettings().then((res) => {
        this.settingsData = res;
      });
    },

    nextBattle() {
      this.$store.commit("champions/setCurrentBattle", null);
      this.$store.commit("champions/setCurrentOpponentId", null);
      this.$router.push("/champions?rematch=true");
    },

    returnToChampions() {
      this.$store.commit("champions/setCurrentBattle", null);
      this.$store.commit("champions/setCurrentOpponentId", null);
      this.$router.push("/champions");
    },
  },
};
</script>

<style scoped lang="scss">
#sidebar-champions {
  height: 100%;
  width: 100%;
  position: relative;
  background-image: linear-gradient(#274b60, #0a1d28);
  border-radius: 5%;

  .menu-container {
    height: 100%;
    background: url(~@/assets/img/slot/sidebar/slotsidebar-frame-main.png)
      center/100% 100% no-repeat;

    .dark-bg {
      background-color: #050d1c;
      box-shadow: 0 0 0.2em #038fa7;
      border-bottom: 0.125em solid #038fa7;
      border-radius: 0.5em;
      margin-left: auto;
      margin-right: auto;
    }

    .head-icon {
      height: 8.188em;
      top: 3.5%;
      background: url(~@/assets/img/slot/sidebar/kk-champions-logo-nofx.png)
        center/contain no-repeat;
    }
  }

  .number-container {
    top: 19.5%;

    .number-text {
      font-size: 1em;
      color: #4de5ff;
      font-family: Ubuntu-bold;
      text-transform: uppercase;
    }

    .number {
      font-size: 1.5em;
      color: #4de5ff;
      font-family: Ubuntu-bold;
      height: 2em;
      width: 60%;
    }
  }

  .timer-container {
    top: 29.5%;

    .timer-text {
      font-size: 1em;
      color: #ff0000;
      font-family: Ubuntu-bold;
      text-transform: uppercase;
    }

    .timer {
      font-size: 1.625em;
      color: #ff0000;
      font-family: Ubuntu-bold;
      height: 2em;
      width: 60%;
    }
  }

  .separator {
    top: 40%;
    width: 104%;
    left: -2%;
    height: 1.375em;
    background: url(~@/assets/img/slot/sidebar/slot-sidebar-divider.png)
      center/100% 100% no-repeat;
  }

  .player-container {
    top: 44.5%;

    .player {
      position: relative;
      text-align: center;

      &.player1 {
        top: 0;
      }

      &.player2 {
        top: 17.2em;
      }

      .player-avatar-container {
        .wreath {
          width: 2.938em;
          height: 4.813em;
          background: url(~@/assets/img/slot/sidebar/kk-champions-wreath-small-sidebar.png)
            center/100% 100% no-repeat;
          top: 1.1em;

          &.left {
            left: -10.2em;
            -moz-transform: scale(-1, 1);
            -webkit-transform: scale(-1, 1);
            -o-transform: scale(-1, 1);
            -ms-transform: scale(-1, 1);
            transform: scale(-1, 1);
          }

          &.right {
            right: -10.2em;
          }
        }

        .player-avatar-container {
          width: 5.875em;
          height: 5.938em;
          border-radius: 0.375em;
          background-color: black;

          &.green {
            outline: 0.14em solid #43ff00;
          }

          .player-avatar {
            width: 100%;
            height: 100%;
            background-size: cover;
          }

          .placeholder-avatar {
            width: 100%;
            height: 100%;
            color: white;
            font-family: Ubuntu-Bold;
            font-size: 3.875em;
            text-shadow: 0 0 0.875em rgba(77, 229, 255, 0.6),
              0 0 0.438em rgba(77, 229, 255, 0.6),
              0 0 0.438em rgba(77, 229, 255, 0.6),
              0 0 0.188em rgba(0, 94, 110, 0.8),
              0 0 0.188em rgba(0, 94, 110, 0.8);
            padding-left: 0.05em;
            padding-top: 0.05em;
          }
        }

        .player-name {
          top: 6.7em;
          font-size: 1em;
          color: #fff8b7;
          font-family: Ubuntu-Bold;
          text-transform: uppercase;
        }
      }

      .subline {
        top: 11.5em;
        font-size: 0.75em;
        color: #8e8e8e;
        font-family: Ubuntu-Medium;
        text-transform: uppercase;
      }

      .gold-amount {
        top: 6.5em;
        font-size: 1.5em;
        color: white;
        font-family: Ubuntu-Medium;
        text-shadow: 0 0 0.875em rgba(77, 229, 255, 0.6),
          0 0 0.438em rgba(77, 229, 255, 0.6),
          0 0 0.438em rgba(77, 229, 255, 0.6), 0 0 0.188em rgba(0, 94, 110, 0.8),
          0 0 0.188em rgba(0, 94, 110, 0.8);
        width: 80%;

        .gold-icon {
          width: 1em;
          height: 1em;
          background: url(~@/assets/img/common/coin1.png) center/100% 100%
            no-repeat;
          margin-left: 5px;
        }
      }

      .lock {
        top: 10.3em;
        width: 1.438em;
        height: 1.938em;
        background: url(~@/assets/img/common/lock-gold.png) center/100% 100%
          no-repeat;
      }

      .waiting-text {
        top: 7em;
        font-family: Ubuntu-Bold;
        font-size: 1em;
        color: #fff8b7;
        text-transform: uppercase;
        padding: 0 3.4em;
      }
    }
  }

  .vs-image {
    top: 13.1em;
    width: 12.938em;
    height: 3.563em;
    background: url(~@/assets/img/slot/sidebar/kk-champions-vs.png) center/100%
      100% no-repeat;
  }

  .ended-overlay {
    top: 32%;
    height: 51.8%;
    width: 97%;

    .ended-headline {
      top: 24%;
      font-size: 1.8em;
      font-family: Ubuntu-Bold;
      color: white;
      text-align: center;
      text-transform: uppercase;
      line-height: 1.2em;
      text-shadow: 0 0 0.875em rgba(77, 229, 255, 0.6),
        0 0 0.438em rgba(77, 229, 255, 0.6), 0 0 0.438em rgba(77, 229, 255, 0.6),
        0 0 0.188em rgba(0, 94, 110, 0.8), 0 0 0.188em rgba(0, 94, 110, 0.8);
      padding: 0 0.8em;
    }

    .ended-text1 {
      top: 49%;
      font-size: 1.125em;
      font-family: Ubuntu-Bold;
      color: white;
      text-align: center;
      line-height: 1.5;
      text-transform: uppercase;
      padding: 0 1.25em;
    }

    .ended-text2 {
      top: 68%;
      font-size: 1.125em;
      font-family: Ubuntu-Bold;
      color: white;
      text-align: center;
      text-transform: uppercase;
      padding: 0 1em;
      line-height: 1.5;
    }

    .ended-button {
      top: 94%;
      width: 80%;
      height: 2.75em;
      border-radius: 1.375em;
      font-size: 1.313em;
      font-family: Ubuntu-Bold;
      text-shadow: 0.063em 0.063em 0.125em #0b2b00, 0 0 1.563em #42ff00,
        0 0 0.313em #0b2b00;
      padding-top: 0.25em;
    }
  }

  .return-overlay {
    top: 32%;
    height: 51.8%;
    width: 97%;

    .return-headline {
      top: 24%;
      font-size: 1.8em;
      font-family: Ubuntu-Bold;
      color: white;
      text-align: center;
      text-transform: uppercase;
      line-height: 1.2em;
      text-shadow: 0 0 0.875em rgba(77, 229, 255, 0.6),
        0 0 0.438em rgba(77, 229, 255, 0.6), 0 0 0.438em rgba(77, 229, 255, 0.6),
        0 0 0.188em rgba(0, 94, 110, 0.8), 0 0 0.188em rgba(0, 94, 110, 0.8);
      padding: 0 0.8em;
    }

    .return-text1 {
      top: 49%;
      font-size: 1.125em;
      font-family: Ubuntu-Bold;
      color: white;
      text-align: center;
      line-height: 1.5;
      text-transform: uppercase;
      padding: 0 1.25em;
    }

    .return-button1 {
      top: 74%;
      width: 80%;
      height: 2.75em;
      border-radius: 1.375em;
      font-size: 1.313em;
      font-family: Ubuntu-Bold;
      text-shadow: 0.063em 0.063em 0.125em #0b2b00, 0 0 1.563em #42ff00,
        0 0 0.313em #0b2b00;
      padding-top: 0.25em;
    }

    .return-button2 {
      top: 94%;
      width: 80%;
      height: 2.75em;
      border-radius: 1.375em;
      font-size: 1.313em;
      font-family: Ubuntu-Bold;
      text-shadow: 0.063em 0.063em 0.125em #0b2b00, 0 0 1.563em #42ff00,
        0 0 0.313em #0b2b00;
      padding-top: 0.25em;
    }
  }
}
</style>
